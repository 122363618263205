/**
 * This Mixin contains computed properties for accessing logger data from the store
 */
import { mapGetters } from 'vuex';

export default {
  computed: {
    // Acts as an aliases to the store
    ...mapGetters({
      loggers: 'loggers/data',
      storeFiles: 'loggers/files',
      storeMeter: 'loggers/meter',
      storeCmds: 'loggers/cmds',
    }),
    lid() {
      return this.$route.params.lid;
    },
    logger() {
      return this.loggers[this.lid];
    },
    serial() {
      return this.logger.serial;
    },
    hasWriteAccess() {
      return this.logger && this.logger.writeAccess;
    },
    isOwner() {
      return this.logger && this.logger.owner;
    },
    files() {
      if (this.storeFiles[this.lid]) {
        return this.storeFiles[this.lid];
      }
      return [];
    },
    cmds() {
      if (this.lid in this.storeCmds) {
        return this.storeCmds[this.lid]
          .filter(cmd => cmd.status === 'PENDING');
      }
      return [];
    },
    meterReadings() {
      return this.storeMeter[this.lid];
    },
  },
};
