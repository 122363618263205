<script>
import JSZip from 'jszip';
import Pako from 'pako';
import { saveAs } from 'file-saver';
import { BIconGrid3x3Gap, BIconGrid3x3GapFill } from 'bootstrap-vue';
import LoggerStore from '@/mixins/LoggerStore';

export default {
  name: 'LoggerPageFileTable',
  components: {
    BIconGrid3x3Gap,
    BIconGrid3x3GapFill,
  },
  props: {
    title: {
      type: String,
      default: 'Files',
    },
    typeFilter: {
      type: String,
      default: 'PLAYBACK',
    },
  },
  mixins: [LoggerStore],
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      fields: [
        {
          key: 'filename',
          label: 'Filename',
          sortable: true,
        },
        {
          key: 'created',
          label: 'Date Created',
          sortable: true,
          formatter: (date) => {
            const newDate = new Date(date.getTime());
            newDate.setMinutes(newDate.getMinutes() + date.getTimezoneOffset());
            return this.$d(newDate, 'long');
          },
        },
        {
          key: 'location',
          label: 'Location',
          sortable: false,
        },
        {
          key: 'id',
          label: 'Actions',
        },
      ],
      selected: [],
      download: {
        showToast: false,
        showProgress: false,
        label: null,
        files: [],
        progress: 0,
        cancel: null,
      },
    };
  },
  computed: {
    totalFiles() {
      return this.storeFiles[this.lid]
        .filter(f => (f.type === this.typeFilter) && f.onDisk)
        .length;
    },
  },
  methods: {
    /**
     * Download the specified file from the system
     */
    downloadFile(file) {
      // Find the status of the file with a preflight request
      this.$http({
        method: 'GET',
        url: `/logger/${this.lid}/files/${encodeURIComponent(file.filename)}`,
        headers: {
          Accept: 'application/json',
        },
      })
        .then((resp) => {
          // Check whether the file is present on the disk or not
          if (!resp.data.onDisk) {
            // Repeat the request w/out accept header to trigger a retreval from the logger
            return this.$http({
              method: 'GET',
              url: `/logger/${this.lid}/files/${encodeURIComponent(file.filename)}`,
            });
          }

          // Start the file download in the background.
          const cancelToken = this.$http.CancelToken;
          const source = cancelToken.source();

          // Setup the UI variables
          this.download.cancel = source;
          this.download.label = 'Downloading file';
          this.download.files = [];
          this.download.progress = 0;
          this.download.showProgress = true;
          this.download.showToast = true;

          // Store the size of the file we are downloading
          this.download.files.push({
            url: file.url,
            total: Number.parseInt(resp.data.size, 10),
            loaded: 0,
          });

          // Now perform the download itself
          return this.$http({
            method: 'get',
            url: resp.data.url,
            responseType: 'arraybuffer',
            encoding: 'null',
            onDownloadProgress: ev => this.dlProgressEv(file.url, ev),
            cancelToken: source.token,
          })
            // Convert the downloaded data into a Uint8Array
            .then(getResp => new Uint8Array(getResp.data))
            // Once the download completes, decompress the file if it's compressed
            .then((getResp) => {
              if (file.compressed) return Pako.inflate(getResp);
              return getResp;
            })
            // Now convert into a blob
            .then(array => new Blob([array.buffer]))
            // Once the download completes, push it to the browsers download handler
            .then(res => saveAs(res, file.filename, { type: 'application/octet-stream' }));
        })
        // Catch all errors
        .catch((err) => {
          if (!err.__CANCEL__) { // eslint-disable-line
            this.$bvToast.toast('An error occurred', {
              variant: 'danger',
              toaster: 'b-toaster-top-center',
            });
          }
        })
        // Finally mark the download as complete
        .finally(() => { this.download.showToast = false; })
        .catch((err) => {
          if (err.reponse === 404 || err.response === 403) {
            this.$bvToast.toast('An Error Occurred when downloading the file', {
              title: 'Error',
              toaster: 'b-toaster-bottom-center',
              variant: 'danger',
              solid: true,
            });
          }
          return err;
        });
    },

    /**
     * Delete a file from the system, from the logger, or both
     */
    deleteFile(filename) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this file?', {
        title: 'Delete File',
      })
        .then((conf) => {
          if (conf) {
            this.$store.dispatch('loggers/deleteFile', {
              lid: this.lid,
              filename,
            });
          }
        });
    },

    /**
     * Delete the selected files from the filesystem
     */
    deleteSelected() {
      // Filter out all of the files that are not on disk
      const validSelections = this.selected.filter(x => x.onDisk);
      const len = validSelections.length;

      this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete ${len} file${len !== 1 ? 's' : ''}`, {
          title: 'Delete Files',
        },
      )
        .then((conf) => {
          if (conf) {
            validSelections.forEach((file) => {
              this.$store.dispatch('loggers/deleteFile', {
                lid: this.lid,
                filename: file.filename,
              });
            });
          }
        });
    },
    /**
     * Filter files so only those in storage are shown
     */
    filterFiles(file, filter) {
      return filter === file.type && file.onDisk;
    },

    /**
     * Function called when a row is selected/deselected
     */
    rowSelected(rows) {
      this.selected = rows;
    },

    /**
     * Returns the name for a multi-file download zip file
     */
    generateZipName() {
      const now = new Date();
      const dateString = `${now.getFullYear()}${now.getMonth()}${now.getDate()}`;
      const timeString = `${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
      return `pmdownload-${this.serial}-${dateString}-${timeString}.zip`;
    },

    /**
     * Multiple File download progress event handler
     */
    dlProgressEv(url, ev) {
      // Find the file object this referrs to in the list
      const i = this.download.files.findIndex(f => f.url === url);

      // Handle a case where the file object can't be found
      if (i === -1) {
        return;
      }

      // Perform some trickery so Vue can detect this change and update the UI
      this.download.files[i].total = ev.total;
      this.download.files[i].loaded = ev.loaded;

      // Compute the total and loaded for all files
      const total = this.download.files.reduce((t, e) => t + e.total, 0);
      const loaded = this.download.files.reduce((t, e) => t + e.loaded, 0);

      // Now set the progress percentage
      this.download.progress = Math.round((loaded / total) * 100);
    },

    /**
     * Download all of the selected files
     */
    downloadSelected() {
      // Generate a cancel token so we can abort part way through
      const token = this.$http.CancelToken;
      const source = token.source();

      // Setup the UI variables
      this.download.files = [];
      this.download.cancel = source;
      this.download.label = 'Downloading files';
      this.download.progress = 0;
      this.download.showProgress = true;
      this.download.showToast = true;

      // Get the final download URL and size for each file
      Promise.all(this.selected.filter(x => x.onDisk)
        .map(f => this.$http({
          method: 'get',
          url: `/logger/${this.lid}/files/${encodeURIComponent(f.filename)}`,
          headers: {
            Accept: 'application/json',
          },
          cancelToken: source.token,
          filename: f.filename,
          compressed: f.compressed,
        })))
        // Map the responses into an array that contains their size and url
        .then(resps => resps.map(resp => ({
          url: resp.data.url,
          total: resp.data.size,
          loaded: 0,
          cancelToken: source.token,
          filename: resp.config.filename,
          compressed: resp.config.compressed,
        })))
        // Now set that array globally
        .then(files => this.$set(this.download, 'files', files))
        // Perform the main file downloads
        .then(() => this.download.files.map(f => this.$http({
          method: 'get',
          url: f.url,
          responseType: 'arraybuffer',
          encoding: 'null',
          onDownloadProgress: ev => this.dlProgressEv(f.url, ev),
          cancelToken: source.token,
          filename: f.filename,
          compressed: f.compressed,
        })))
        // Wait for them all to finish
        .then(resps => Promise.all(resps))
        // Update the download label
        .then((resps) => {
          this.download.label = 'Zipping files up';
          this.download.showProgress = false;
          return resps;
        })
        // Convert the file in each request into a Uint8Array
        .then(resps => resps.map(x => ({
          name: x.config.filename,
          data: new Uint8Array(x.data),
          compressed: x.compressed,
        })))
        // Decompress each file
        .then(resps => resps.map((x) => {
          // Decompress any files that are marked as compressed
          if (x.compressed) return { name: x.name, data: Pako.inflate(x.data) };
          return x;
        }))
        // Bundle all of the files into a single zip
        .then(files => files.reduce(async (zip, currFile) => {
          // Wait for the previous iteration to complete
          const jszip = await zip;
          // Load the zip file in curr into accum
          return jszip.file(currFile.name, currFile.data, { binary: true });
        }, Promise.resolve(new JSZip())))
        // Now the zip file has been created in memory, generate a blob for it
        .then(zip => zip.generateAsync({ type: 'blob', compression: 'DEFLATE' }))
        // Push to the browser's downloads
        .then(zipBlob => saveAs(zipBlob, this.generateZipName(), { type: 'application/zip' }))
        // Catch any errors that occur here
        .catch((err) => {
          if (!err.__CANCEL__) { // eslint-disable-line
            this.$bvToast.toast('Error downloading files', {
              variant: 'danger',
              toaster: 'b-toaster-top-center',
            });
          }
        })
        // Finally mark the download as complete
        .finally(() => { this.download.showToast = false; });
    },

    /**
     * Cancel the current download
     */
    cancelDownload() {
      if (!this.download.cancel) {
        return;
      }

      // Cancel the download
      this.download.cancel.cancel();
    },

    /**
     * Activate the selected config file on the logger
     * Parameter 'configName' temporarily removed
     */
    activateConfig() {
      this.$bvModal.msgBoxConfirm(
        `Queueing a logger config for loading is irreversible and can only be undone by queuing
        a different configuration.`,
        {
          title: 'Confirm Config Activation',
          okTitle: 'Load Config (not yet implemented)',
          okDisabled: true,
        },
      )
        // Parameter 'conf' temporarily removed
        .then(() => {
          // if (conf) {
          // this.$store.dispatch('loggers/patchFile', {
          // lid: this.lid,
          // filename: configName,
          // promptLoad: true,
          // });
          // }
        });
    },

    /**
     * Send a config file to the logger
     */
    sendToLogger(configName) {
      this.$store.dispatch('loggers/patchFile', {
        lid: this.lid,
        filename: configName,
        promptDownload: true,
      })
        .then(() => this.$store.dispatch('loggers/getFiles', this.lid));
    },

    /**
     * Used to determine whether the activeate button should be enable
     */
    enableActivateButton(item) {
      return item.type === 'CONFIGURATION' && item.onLogger && !item.promptLoad;
    },

    /**
     * Used to determine whether the activate button should show that the config is queued
     */
    enableAlreadyActivatedButton(item) {
      return item.type === 'CONFIGURATION' && item.onLogger && item.promptLoad;
    },

    /**
     * Used to determine whether the send to logger button should be enabled
     */
    enableSendToLoggerButton(item) {
      return item.type === 'CONFIGURATION' && !item.onLogger && !item.promptDownload;
    },

    /**
     * Used to determine whether the already sending to logger button should be activated
     */
    enableAlreadySendingToLoggerButton(item) {
      return item.type === 'CONFIGURATION' && !item.onLogger && item.promptDownload;
    },

    loggerIconTooltipText(onLogger, promptDownload) {
      if (promptDownload) {
        return 'Queued for download to Logger';
      }

      if (onLogger) {
        return 'File is present on Logger';
      }

      // Else
      return 'File not present on Logger';
    },

    diskIconTooltipText(onDisk) {
      return onDisk ? 'File is present on PMGateway' : 'File not present on PMGateway';
    },
  },
};
</script>

<template>
  <b-card>
    <!-- Files Card Header -->
    <template slot='header'>

      <!-- Header Text -->
      <div class='float-left'>
        {{ title }}
      </div>

      <!-- Multi-file actions -->
      <div class='float-right'>
        <b-btn size='sm'
               class='mr-2'
               variant='secondary'
               @click='$refs.fileTable.selectAllRows()'
               v-b-popover.hover.bottom="'Select All'">
          <b-icon-grid3x3-gap-fill/>
        </b-btn>
        <b-btn size='sm'
               variant='secondary'
               class='mr-4'
               @click='$refs.fileTable.clearSelected()'
               v-b-popover.hover.bottom="'Select None'">
          <b-icon-grid3x3-gap/>
        </b-btn>
        <b-btn size='sm'
               class='mr-2'
               @click='downloadSelected()'
               v-bind:disabled='this.selected.length === 0'
               v-b-popover.hover.bottom="'Download Selected'">
          <fa-icon icon='download'/>
        </b-btn>
        <b-btn size='sm'
               variant='danger'
               @click='deleteSelected()'
               v-bind:disabled='this.selected.length === 0'
               v-b-popover.hover.bottom="'Delete Selected'">
          <fa-icon icon='trash'/>
        </b-btn>
      </div>

    </template>

    <!-- Beginning of table -->
    <b-table id='fileTable'
             :items="files"
             :filter="this.typeFilter"
             :filter-function="filterFiles"
             :fields="fields"
             :current-page="currentPage"
             :per-page="itemsPerPage"
             @row-selected="this.rowSelected"
             sort-icon-left
             responsive
             selectable>

      <template v-slot:cell(filename)="data">
        <span>{{ data.item.filename }}</span>
        <b-badge v-if='data.item.filename === logger.currentConfig' variant='success' class='ml-2'>
          Loaded
        </b-badge>
        <b-badge v-if='data.item.promptLoad' variant='warning' class='ml-2'>
          Loaded for next session
        </b-badge>
      </template>

      <!-- Location cell for each row -->
      <template v-slot:cell(location)="data">
        <!-- Icon to show whether the file is present on the server -->
        <fa-icon icon='database'
                 v-if='data.item.onDisk'
                 size='lg'
                 v-b-tooltip.hover.top="diskIconTooltipText(data.item.onDisk)"
                 v-bind:class="{
                   'm-1': true,
                   available: data.item.onDisk,
                   unavailable: !data.item.onDisk,
                 }" />

        <fa-icon icon='arrow-alt-circle-right'
        size='lg'
        v-b-tooltip.hover.top="loggerIconTooltipText(data.item.onLogger, data.item.promptDownload)"
        v-bind:class="{
          'm-1': true,
          available: data.item.onLogger,
          unavailable: !data.item.onLogger,
          pending: data.item.promptDownload,
          'hide-icon': !data.item.promptDownload,
        }" />

        <!-- Icon to show whether the file is present on a logger -->
        <fa-icon icon='microchip'
        size='lg'
        v-b-tooltip.hover.top="loggerIconTooltipText(data.item.onLogger, data.item.promptDownload)"
        v-bind:class="{
          'm-1': true,
          available: data.item.onLogger,
          unavailable: !data.item.onLogger,
        }" />
      </template>

      <!-- Actions cell for each row -->
      <template v-slot:cell(id)="data">
        <b-btn-group>

          <!-- Actions menu for config files that are present on logger -->
          <b-dropdown v-if="enableActivateButton(data.item)"
                      v-b-popover.hover="'Activate for Next Session'"
                      @click="activateConfig(data.item.filename)"
                      split>

            <!-- Icon shown in button -->
            <template v-slot:button-content>
              <fa-icon icon='sign-in-alt'></fa-icon>
            </template>

            <!-- Dropdown List Begins -->
            <b-dropdown-item @click="downloadFile(data.item)">
              <div v-if='data.item.onDisk'>
                <fa-icon icon='download'></fa-icon> Download
              </div>
              <div v-else>
                <fa-icon icon='cloud-download-alt'></fa-icon> Fetch from Logger
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteFile(data.item.filename, 'pmgateway')"
              v-bind:disabled='!data.item.onDisk || !hasWriteAccess'>
              <fa-icon icon='trash'></fa-icon> Delete from PMGateway
            </b-dropdown-item>
            <!-- End of Dropdown List -->

          </b-dropdown>
          <!-- End of Config file (on logger) action menu -->

          <!-- Actions menu for config files that are NOT present on logger -->
          <b-dropdown v-b-popover.hover="'Send to Logger'"
                      v-else-if="enableSendToLoggerButton(data.item)"
                      @click="sendToLogger(data.item.filename)"
                      split>

            <!-- Icon shown in button -->
            <template v-slot:button-content>
              <fa-icon icon='cloud-download-alt'></fa-icon>
            </template>

            <!-- Dropdown List Begins -->
            <b-dropdown-item @click="downloadFile(data.item)">
              <div v-if='data.item.onDisk'>
                <fa-icon icon='download'></fa-icon> Download
              </div>
              <div v-else>
                <fa-icon icon='cloud-upload-alt'></fa-icon> Fetch from Logger
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteFile(data.item.filename, 'pmgateway')"
              v-bind:disabled='!data.item.onDisk || !hasWriteAccess'>
              <fa-icon icon='trash'></fa-icon> Delete from PMGateway
            </b-dropdown-item>
            <!-- End of Dropdown List -->

          </b-dropdown>
          <!-- End of Config file (NOT on logger) action menu -->

          <!-- Actions menu for config files that are pending logger download -->
          <b-dropdown v-else-if="enableAlreadySendingToLoggerButton(data.item)"
                      v-b-popover.hover="'Pending download'"
                      split>

            <!-- Icon shown in button -->
            <template v-slot:button-content>
              <fa-icon icon='cogs'></fa-icon>
            </template>

            <!-- Dropdown List Begins -->
            <b-dropdown-item @click="downloadFile(data.item)">
              <div v-if='data.item.onDisk'>
                <fa-icon icon='download'></fa-icon> Download
              </div>
              <div v-else>
                <fa-icon icon='cloud-upload-alt'></fa-icon> Fetch from Logger
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteFile(data.item.filename, 'pmgateway')"
              v-bind:disabled='!data.item.onDisk || !hasWriteAccess'>
              <fa-icon icon='trash'></fa-icon> Delete from PMGateway
            </b-dropdown-item>
            <!-- End of Dropdown List -->

          </b-dropdown>
          <!-- End of Config file (NOT on logger) action menu -->

          <!-- Actions Manu for any other file (inc. playback) -->
          <b-dropdown v-else split @click='downloadFile(data.item)'>

            <!-- Icon shown in button -->
            <template v-slot:button-content>
              <fa-icon v-if='data.item.onDisk' icon='download'></fa-icon>
              <fa-icon v-else icon='cloud-upload-alt'></fa-icon>
            </template>

            <!-- Dropdown List Begins -->
            <b-dropdown-item @click="downloadFile(data.item)">
              <div v-if='data.item.onDisk'>
                <fa-icon icon='download'></fa-icon> Download
              </div>
              <div v-else>
                <fa-icon icon='cloud-upload-alt'></fa-icon> Fetch from Logger
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteFile(data.item.filename, 'pmgateway')"
              v-bind:disabled='!data.item.onDisk || !hasWriteAccess'>
              <fa-icon icon='trash'></fa-icon> Delete from PMGateway
            </b-dropdown-item>
            <!-- End of Dropdown List -->

          </b-dropdown>
          <!-- End of dropdown for other file types -->

        </b-btn-group>
      </template>
      <!-- End of Action Cell -->

    <!-- End of Table -->
    </b-table>

    <!-- Footer of Files Card -->
    <template v-slot:footer>
      <b-container fluid>
        <b-row align-h='center' no-gutters>
          <b-col cols='auto'>
            <!-- Table Page Selector -->
            <b-pagination
              v-model="currentPage"
              :total-rows= "totalFiles"
              :per-page="itemsPerPage">
            </b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <!-- Download Progress Toast -->
    <b-toast title='Download in Progress'
              variant='secondary'
              v-bind:visible='this.download.showToast'
              toaster='b-toaster-bottom-right'
              no-auto-hide
              no-close-button
              is-status>
      <b-spinner small class='mr-2'></b-spinner>
      <span class='mr-1'>{{ this.download.label }}</span>
      <span v-if='this.download.showProgress'>({{ this.download.progress }}%)</span>
      <b-btn variant='outline-danger'
              size='sm'
              class='ml-2'
              v-bind:disabled='!this.download.showProgress'
              @click='cancelDownload()'>
        Cancel
      </b-btn>
    </b-toast>
  </b-card>
</template>

<style lang="scss" scoped>
  @import '@/style/bs-theme.scss';

  // Colour definitions for the various states of icons
  $available: $success;
  $pending: $gray-500;
  $unavailable: $gray-500;
  $blinking: $success;

  .available {
    color: $available;
  }

  .pending {
    animation: blinkingIcon 0.8s infinite;
  }

  .unavailable {
    color: $unavailable;
  }

  .to-be-activated {
    color: $pending;
  }

  .hide-icon {
    visibility: hidden;
  }

  @keyframes blinkingIcon {
    0% { color: $blinking; }
    25% { color: $blinking; }
    50% { color: $gray-500; }
    75% { color: $gray-500; }
    100% { color: $blinking; }
  }
</style>
