/**
 * This Mixin contains a set of functions for UI data formatting
 */

export default {
  methods: {
    /**
     * Get the capitalisation of the status correct
     */
    formatStatus(val) {
      return val
        .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
        .replace(/^[^ ]/g, match => (match.toUpperCase()));
    },
    /**
     * Format a value in bytes to a human readable string
     */
    formatFileSize(bytes) {
      if (bytes < 1024) {
        return `${bytes} bytes`;
      } if (bytes < 1024000) {
        return `${(bytes / 1000).toFixed(2)} kB`;
      } if (bytes < 1024000000) {
        return `${(bytes / 1000000).toFixed(2)} MB`;
      }
      return `${(bytes / 1000000000).toFixed(2)} GB`;
    },
  },
};
