<script>
export default {
  name: 'ModemSignalIcon',
  props: {
    bars: {
      type: Number,
      required: true,
    },
    offline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    barClass(barNum) {
      if (!this.offline && barNum <= this.bars) {
        return 'signal-bar-true';
      }
      return 'signal-bar-false';
    },
  },
};
</script>

<template>
  <div class='modem-signal-component'>
    <span class='text-muted mb-2'><slot></slot></span>
    <svg width='40' height='30'>
      <rect x='0' y='26' width='4' height='4' :class='this.barClass(1)'/>
      <rect x='6' y='24' width='4' height='6' :class='this.barClass(2)'/>
      <rect x='12' y='20' width='4' height='10' :class='this.barClass(3)'/>
      <rect x='18' y='14' width='4' height='16' :class='this.barClass(4)'/>
      <rect x='24' y='8' width='4' height='22' :class='this.barClass(5)'/>
      <line v-show='this.offline'
            x1='30' y1='1' x2='38' y2='9' stroke-width='2' style='stroke: black' />
      <line v-show='this.offline'
            x1='38' y1='1' x2='30' y2='9' stroke-width='2' style='stroke: black' />
    </svg>
  </div>
</template>

<style lang='scss' scoped>
  @import '@/style/bs-theme.scss';

  .signal-bar-true {
    fill: $black;
  }

  .signal-bar-false {
    fill: $gray-500;
  }

  .text-muted {
    display: inline-block;
    vertical-align: -5px;
    padding-right: 5px;
  }

  svg {
    display: inline-block;
  }
</style>
