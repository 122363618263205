<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
} from 'vue2-leaflet';

export default {
  name: 'MapLocationPicker',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  props: {
    center: {
      type: Array,
      required: false,
      default: () => [48.3544, -99.9981],
    },
    markerLocation: {
      type: Array,
      required: false,
    },
    zoom: {
      type: Number,
      required: false,
      default: 7,
    },
    value: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      centerData: [48.3544, -99.9981],
      zoomData: 7,
      visible: !!this.value,
    };
  },
  mounted() {
    this.centerData = this.center;
    this.zoomData = this.zoom;
  },
  methods: {
    resize() {
      setTimeout(() => this.$refs.map.mapObject.invalidateSize(), 100);
    },
    zoomUpdated(zoom) {
      this.zoomData = zoom;
    },
    centerUpdated(center) {
      this.centerData = center;
    },
    markerDragged(e) {
      this.$emit('input', [e.lat, e.lng]);
    },
    mapClicked(e) {
      this.$refs.userMarker.setLatLng(e.latlng);
      this.$emit('input', [e.latlng.lat, e.latlng.lng]);
      this.visible = true;
    },
  },
};
</script>

<template>
  <l-map
    style='height: 300px; width: 300px'
    ref='map'
    :zoom='zoomData'
    v-bind:center='centerData'
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
    @click="mapClicked">
    <l-tile-layer :url='url' />
    <l-marker :visible='!!markerLocation'
              :lat-lng='markerLocation'>
      <l-tooltip>Logger reported location</l-tooltip>
    </l-marker>
    <l-marker v-bind:visible='visible'
              ref='userMarker'
              :draggable='true'
              :lat-lng='value || centerData'
              @update:latLng='markerDragged' />
  </l-map>
</template>
