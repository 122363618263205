<script>
export default {
  name: 'UserSearchBox',
  data() {
    return {
      searchEmail: '',
      errorState: null,
    };
  },
  methods: {
    /**
     * Perform the search in the database
     */
    searchForUser() {
      this.$http({
        methiod: 'GET',
        url: '/user',
        params: {
          q: this.searchEmail,
        },
      })
        .then((resp) => {
          // Emit the returned user if it's found
          const result = {
            id: resp.data.id,
            name: resp.data.name,
            email: resp.data.email,
            company: resp.data.company,
          };
          this.errorState = null;
          this.$emit('input', result);
        })
        .catch(() => {
          // Display the error text if the user is not found
          this.errorState = false;
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row class='mt-2'>
      <b-col cols=12>

        <!-- A label for the component -->
        <label>
          Search for a User
        </label>

        <!-- The main search bar and button -->
        <b-input-group>
          <b-input v-model='searchEmail'
                    placeholder='Email address'
                    v-bind:state='errorState'
                    v-on:keyup.enter="searchForUser()" />
          <b-input-group-append>
            <b-btn variant='success'
                    @click='searchForUser()'
                    v-bind:disabled='searchEmail.length < 1'>
              <fa-icon icon='search' />
            </b-btn>
          </b-input-group-append>
        </b-input-group>

        <!-- Text to display when a search fails -->
        <b-form-invalid-feedback v-bind:state='errorState'>
          That user was not found
        </b-form-invalid-feedback>

      </b-col>
    </b-row>
  </b-container>
</template>
